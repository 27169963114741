import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfigs } from 'src/app/common/app-configs';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { DatatableService } from 'src/app/services/datatable/datatable.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { columns } from '../../projects/utility/column-generator';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import * as lodashArray from "lodash";
import { GridService } from 'src/app/services/grid.service';
import swal from 'sweetalert2';
import { ThumbnailModalServiceService } from 'src/app/services/thumbnail-modal-service.service';
import * as moment from 'moment';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-projects-grid',
  templateUrl: './projects-grid.component.html',
  styleUrls: ['./projects-grid.component.css']
})
export class ProjectsGridComponent implements OnInit {

  projectsColumnData = {};
  isMangeColumnsOpened: boolean = false;
  _loadManageColumnsSideWindow: boolean = false;
  apiRoutePrefixColumn = 'users';

  headerDropdowns: any = { status: '', episodic_series: '', project_type: '', country: '', client: '', category: '' };
  statusTagOptions: Array<{ id: number, name: string }> = [{ id: 1, name: "Active" }, { id: 0, name: "Exit" }];
  vendorTypeOptions: Array<{ id: number, name: string }> = [{ id: 1, name: 'Vendor' }, { id: 2, name: 'Freelance' }];
  projectTypeOptions: Array<any> = [];
  countryTagOptions: Array<any> = [];
  clientTagOptions: Array<any> = [];
  categoryTagOptions: Array<any> = [];
  statusOptions = [
    { id: 'In Bidding', text: 'In Bidding' },
    { id: 'In Production', text: 'In Production' },
    { id: 'On Hold', text: 'On Hold' },
    { id: 'Cancelled', text: 'Cancelled' },
    { id: 'Completed', text: 'Completed' }
  ];
  episodicSeriesOptions = [{ id: 1, text: "Yes" }, { id: 0, text: "No" }];
  statusTag = {
    'In Bidding': { className: 'icon-color-blue material-icons', display: 'brightness_1', tooltip: 'In Bidding' },
    'In Production': { className: 'icon-color-MossGreen material-icons ', display: 'brightness_1', tooltip: 'In Production' },
    'On Hold': { className: 'icon-color-Gold material-icons ', display: 'brightness_1', tooltip: 'On Hold' },
    'Cancelled': { className: 'icon-color-light-grey material-icons', display: 'brightness_1', tooltip: 'Cancelled' },
    'Completed': { className: 'icon-color-ForestGreen material-icons', display: 'brightness_1', tooltip: 'Completed' }
  };
  checkFilters = { 'status': [], 'secondary_department': [], 'vendor_type': [] };
  projectObj: any = {};
  projectDetail = {};
  rows: any = [];
  temp = [];
  currentPage: number = 0;
  openedPage: number = 0;
  totalItems: number = 0;
  totalCount: number = 0;
  pageSize: number = 100;
  isLoading: boolean = true;
  pagePayload: any = { page: 0, itemsPerPage: 50 };
  public readonly pageLimitOptions = [
    { value: 50 },
    { value: 100 },
    { value: 200 },
    { value: 300 },
  ];
  public dataTableColumns: TableColumn[] = [];

  _ng_sidebar_data = {};

  _opened: boolean = false;
  _loadFilterSideWindow: boolean = false;
  _loadSideWindow: boolean = false;
  _import_opened: boolean = false;
  _ng_import_type: string;
  _loadImportSideWindow: boolean = false;
  fromNotify: any;
  uploadUrl = this.commonJs.getUploadUrl();

  tableGridHeight = { headerHeight: 90, rowHeight: 40, footerHeight: 50 };
  deleteAllId = [];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('thumbnail', { static: false }) public thumbnail: TemplateRef<any>;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  @ViewChild('name', { static: false }) public name: TemplateRef<any>;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild('length', { static: false }) public length: TemplateRef<any>;

  @ViewChild('startDate', { static: false }) public startDate: TemplateRef<any>;
  @ViewChild('endDate', { static: false }) public endDate: TemplateRef<any>;

  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild("hdrDropdownTpl", { static: true }) hdrDropdownTpl: TemplateRef<any>;

  //Config var
  // public FilterColumns: Array<any> = ['name', 'email'];
  datepickerDefault: any = { start_date: '', end_date: '' };
  public config: any = {
    FilterByColumns: {
      "status": "",
      "project_code": "",
      "name": "",
      "fps": "",
      "episodic_series": "",
      "project_type": "",
      "output_resolution": "",
      "country": "",
      "client": "",
      "length": "",
      "bid_days": "",
      "start_date": "",
      "end_date": "",
      "category": ""
    },
    FilterColumns: [
      "name"
    ],
    export: false,
    filtering: { filterString: '' },
    itemsPerPage: this.pagePayload.itemsPerPage,
    sort: {
      className: "hide-this",
      name: "id",
      sort: "desc",
      title: "#ID"
    },
    sortNew: { id: 'desc' },
    // sorting: {},
    start: this.pagePayload.page,
    where: {},

  };
  settings: any = JSON.parse(localStorage.getItem('settings'));
  isFilterApplied: boolean = false;
  public rangePicker = {
    singleDatePicker: false,
    autoUpdateInput: false,
    showDropdowns: true,
    autoApply: false,
    drops: 'down',
    //startDate: '',
    locale: { format: this.settings.date_format },
  }
  bsModalRef: BsModalRef;

  constructor(
    private titleService: Title,
    private dataService: DataService,
    private datatableService: DatatableService,
    private router: Router,
    public appConfigs: AppConfigs,
    private toastrService: ToastrService,
    public commonJs: CommonJs,
    private activatedRoute: ActivatedRoute,
    private gridService: GridService,
    private thumbnailModalService: ThumbnailModalServiceService,
    private modalService: BsModalService,
  ) {
    this.projectObj.isProjectPermissions = {};
    this.projectObj.modifiedArray = [];
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.settings.html_prefix + ' Projects');

    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectObj.projectId = params['id'];
      // this.config.where.project_id = this.projectObj.projectId;
    });

    this.projectObj.isProjectPermissions['add'] = this.commonJs.checkAccess('projects', 'add', { 'baiPass': ['admin'] }, this.projectObj.projectId);
    this.projectObj.isProjectPermissions['delete'] = this.commonJs.checkAccess('projects', 'delete', { 'baiPass': ['admin'] }, this.projectObj.projectId);
    this.projectObj.isProjectPermissions['edit'] = this.commonJs.checkAccess('projects', 'edit', { 'baiPass': ['admin'] }, this.projectObj.projectId);
    this.projectObj.isProjectPermissions['view'] = this.commonJs.checkAccess('projects', 'view', { 'baiPass': ['admin'] }, this.projectObj.projectId);

    this.createColumns([]);
    /** Help modal show when you open from the  import modal window*/
    this.dataService.notifyObservable$.subscribe(res => {
      if (res.refresh) {
        this._import_opened = true;
        this._ng_import_type = 'projects';
        this.fromNotify = 'modalImport';
      }
    });
  }

  // open glbal image thumbnail viewer
  openImageModal(imageUrl: string): void {
    this.thumbnailModalService.openThumbnailModal(imageUrl);
  }


  /** Create Column */
  private createColumns(modifiedArray?): void {
    let cellTemplate: any = {};
    setTimeout(() => {
      cellTemplate = {
        thumbnail: this.thumbnail,
        idsT: this.idsT,
        name: this.name,
        status: this.status,
        length: this.length,

        startDate: this.startDate,
        endDate: this.endDate,

        hdrTpl: this.hdrTpl,
        hdrDropdownTpl: this.hdrDropdownTpl,
      };
      this.dataTableColumns = [];
      this.dataTableColumns = columns('projects', cellTemplate, modifiedArray);
      this.getProjectList();
    });

    this.columnSet().then((response: any) => {
      if (response.status == 'success' && response.data.length != 0) {
        const rearrangedColumns = [];
        for (const item of response.data) {
          const matchingColumn = this.dataTableColumns.find(obj => obj.prop === item.prop && item.show);
          if (matchingColumn) {
            rearrangedColumns.push(matchingColumn);
          }
        }
        for (const obj of this.dataTableColumns) {
          if (!response.data.some(item => item.prop === obj.prop && item.show)) {
            rearrangedColumns.push(obj);
          }
        }
        this.dataTableColumns = rearrangedColumns;
        this.dataTableColumns = this.dataTableColumns.filter((obj: any) => {
          if (!obj.dynamicColumn) {
            return response.data.some(item => item.prop === obj.prop && item.show);
          } else {
            return obj
          }
        });

      }
      // this.dataTableColumns.unshift(this.checboxColum);
    }, (err: any) => {
      console.warn("Error while fetching column set")
    });

  }

  // on sidewindow open
  onOpened(type?) {
    if (type == 'manage_col') {
      this._loadManageColumnsSideWindow = true;
    } else {
      this._loadSideWindow = true;
    }
  }

  // on window close
  onClosed(type?) {
    if (type == 'manage_col') {
      this._loadManageColumnsSideWindow = false;
    } else {
      this._loadSideWindow = false;
      this._opened = false;
    }
  }

  /**close modeal event */
  closeManageCol(value) {
    if (value.reload) {
      this.columnSet().then((response: any) => {
        if (response.status == 'success') {

          this.createColumns(this.projectObj.modifiedArray);
        }
      }, (err: any) => {
        console.warn("Error while fetching column set")
      });
    }
  }

  /** Get column preferance  */
  columnSet() {
    return new Promise((resolve, rejected) => {
      this.dataService.getListData(this.apiRoutePrefixColumn + '/' + 'projects_list_column' + '/preferences', '')
        .subscribe((success) => {
          if (success.data != false) {
            this.datatableService.setParam(success.data, 'columns');
            resolve(success);
          } else {
            resolve(success);
          }
        }, (rjt) => {
          rejected(rjt);
        });
    });
  }

  // on column sorted
  onSort(event) {
    this.table.offset = this.openedPage;
  }

  // on pagination changed
  firstTimeLoad: boolean = false;
  onPageChange(e): void {
    this.openedPage = e.offset;
    this.table.offset = this.openedPage;
    // this.currentPage = e.offset;
    this.pagePayload.page = e.offset * this.pageSize;
    this.config.start = this.pagePayload.page;
    if (this.firstTimeLoad) {
      this.table.offset = this.openedPage;
      this.dataService.pagePreferancePost({
        pageSize: this.pageSize,
        currentPage: this.config.start,
        pageIndex: this.table.offset,
      }, 'project_list_page');
      this.getProjectList();
    } else {
      this.dataService.pagePreferanceGet('project_list_page').then((res: any) => {
        if (res.data.length == undefined) {
          this.pageSize = res.data.pageSize;
          this.config.itemsPerPage = res.data.pageSize;
          this.config.start = res.data.currentPage;
          this.table.offset = res.data.pageIndex;
          this.getProjectList();
        } else {
          this.getProjectList();
        }
      }, (err: any) => {
        console.warn("Error while fetching page set")
      });
    }
    this.firstTimeLoad = true;
  }

  // on user event
  onUserEvent(event) {
    if (event.type == "click" && event.column.prop == "project_code") {

      const row = event.row;
      const column = event.column.prop;
      if (column == 'project_code') {
        this.commonJs.getProjectRole(row.id).then((res: any) => {
          this.dataService.getDataById('getProjectById/' + row.id)
            .subscribe((success) => {
              // console.log("success",row)
              this.dataService.clientCode = this.commonJs.getClientCode();
              localStorage.setItem('localStorageProjectData', JSON.stringify(success.data));
              this.router.navigate([this.commonJs.getClientCodePrefix() + '/projects/dashboard/' + row.id]);
            }, (error) => {
              if (error.error) {
                // console.log("error",row)
                var err = error.error;
                this.toastrService.notify('warn', '', err.message);
                this.router.navigate(['/dashboard']);
              }
            });
        }).catch((error: any) => {
        })
      }

      //  this.cellClick(row, column.prop);
    }
  }

  // TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
  public onLimitChange(limit: any): void {
    this.changePageLimit(limit);
    this.table.limit = this.pageSize;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor((this.table.rowCount - 1) / this.table.limit);
      }
    });
  }

  private changePageLimit(limit: any): void {
    this.pageSize = parseInt(limit, 10);
    this.config.itemsPerPage = limit;
    this.dataService.pagePreferancePost({
      pageSize: this.pageSize,
      currentPage: this.config.start,
      pageIndex: this.table.offset,
      user_id: JSON.parse(localStorage.getItem('user')).id
    }, 'project_list_page');
    this.getProjectList();
  }

  // To open side window
  openSideWindow(mode, id) {
    if (id != undefined && id != '') {
      this._ng_sidebar_data['projectid'] = id;
    }
    this._ng_sidebar_data['mode'] = mode;
    this._opened = true;
  }

  refreshFilter() {
    this.getProjectList();
  }

  clearFilter() {
    this.datepickerDefault = { start_date: null, end_date: null };
    this.headerDropdowns = { status: '', episodic_series: '', project_type: '', country: '', client: '', category: '' };
    this.config.where = {};
    this.config.FilterByColumns = {
      "status": "",
      "project_code": "",
      "name": "",
      "fps": "",
      "episodic_series": "",
      "project_type": "",
      "output_resolution": "",
      "country": "",
      "client": "",
      "length": "",
      "bid_days": "",
      "start_date": "",
      "end_date": "",
      "category": ""
    };
    this.isFilterApplied = false;
    this.getProjectList();
  }

  closeCreateProject() {
    this.getProjectList();
  }

  // For dropdown auto complete
  search(event, column) {

    if (column == 'episodic_series') {
      this.episodicSeriesOptions = this.episodicSeriesOptions.map((o) => { return o; });

    }
    else if (column == 'status') {
      this.statusOptions = this.statusOptions.map((o: any) => { return o; });

    }
    else if (column == 'project_type') {
      // this.projectTypeOptions = this.projectTypeOptions.map((o) => {  return o; });
      this.dataService.getOtherData('searchItemList', { list_name: 'prj_type', 'query': event['query'] })
        .subscribe((success) => {
          this.projectTypeOptions = success.data;

        }, (rejected) => {

        });

    }
    else if (column == 'country') {
      this.countryTagOptions = this.appConfigs.prj_country.map(v => { return v });

    }
    else if (column == 'category') {
      this.categoryTagOptions = this.appConfigs.prj_category.map(v => { return v });
    }
    else if (column == 'client') {
      this.dataService.getListData('getClients', '')
        .subscribe((success) => {
          this.clientTagOptions = success.data;

        }, (rejected) => {

        });

    }

  }


  // on select from filter dropdown
  selectedOptionChange(column, selectedObj, isMain, isTypeAhead) {

    this.config.start = 0;

    this.isFilterApplied = true;
    let onlyIds;
    if (isTypeAhead) {
      if (column == 'secondary_department') {
        onlyIds = (selectedObj != '' && selectedObj != 'All') ? [selectedObj.id] : [];
        if (isMain) {
          this.checkFilters[column].push(selectedObj);
        } else {
          if (column == 'seconadary_department') {
            this.checkFilters[column] = (selectedObj != '' && selectedObj != 'All') ? [{ id: selectedObj.id, name: selectedObj.name }] : [];
          }
        }
      }
      else if (column == 'project_type') {
        onlyIds = (selectedObj != '' && selectedObj != 'All') ? [selectedObj.item_name] : [];
        this.config.where['project_type'] = onlyIds;
        this.getProjectList();
      }
      else if (column == 'client') {
        onlyIds = (selectedObj != '' && selectedObj != 'All') ? [selectedObj.id] : [];
        this.config.where['client'] = onlyIds;
        this.getProjectList();
      }
      else if (column == 'country') {
        onlyIds = (selectedObj != '' && selectedObj != 'All') ? [selectedObj.id] : [];
        this.config.where['country'] = onlyIds;
        this.getProjectList();
      }
      else {
        onlyIds = (selectedObj != '' && selectedObj != 'All') ? [selectedObj.id] : [];
        if (isMain) {
          this.checkFilters[column].push(selectedObj);
        } else {
          if (column == 'primary_department') {
            this.checkFilters[column] = (selectedObj != '' && selectedObj != 'All') ? [{ id: selectedObj.id, department: selectedObj.department }] : [];
          } else {
            this.checkFilters[column] = (selectedObj != '' && selectedObj != 'All') ? [{ id: selectedObj.id, name: selectedObj.name }] : [];
          }
        }
      }
    } else {
      this.checkFilters[column] = (selectedObj != '' && selectedObj != 'All') ? [selectedObj] : [];
      onlyIds = (selectedObj != '' && selectedObj != 'All') ? [selectedObj] : [];
    }


    if (!isMain) {
      setTimeout(() => {
        //this.columns.push({title: 'new', name: 'name', width: '10%' });
        this.config.where[column] = onlyIds;
        this.getProjectList();
      }, 2);
    }
  }

  // Select data picker
  selectedDatepicker(column, $event, type) {
    this.isFilterApplied = true;
    let startDateValue = moment($event.start).format('YYYY-MM-DD');
    let searchValue = '';

    if (type == 'daterangepicker') {
      let endDateValue = moment($event.end).format('YYYY-MM-DD');
      if (startDateValue != '' && startDateValue != null && startDateValue != 'Invalid date' && endDateValue != '' && endDateValue != null && endDateValue != 'Invalid date') {
        searchValue = startDateValue + '|' + endDateValue;
      }
      this.datepickerDefault[column] = `${moment($event.start).format(this.settings.date_format)} - ${moment($event.end).format(this.settings.date_format)}`;
    } else {

      if (startDateValue != '' && startDateValue != null && startDateValue != 'Invalid date') {
        searchValue = startDateValue;
      }
    }

    if (searchValue != '') {
      setTimeout(() => {
        //this.columns.push({title: 'new', name: 'name', width: '10%' });
        // this.page = 1;
        this.config.where[column] = searchValue;
        this.config.start = 0;
        this.getProjectList();
      }, 2);
    }
  }

  // When a filter is applied
  onColumnFilter(e: any, id: string): void {
    this.config.start = 0;
    this.isFilterApplied = true;
    this.getProjectList();
  }

  public actionClick(action: string, row: any): void {
    let id: number = row.id;
    let vendor_code: number = row.vendor_code;
    switch (action) {
      case ("actionDelete"):
        this.confirmDelete(id, row.name);
        break;
      case ("actionEditSideWindow"):
        this.openSideWindow('edit', id);
        break;
      case ("actionViewSideWindow"):
        this.openSideWindow('view', id);
        break;
      default:
        break;
    }
  }

  // When user clicks in cell
  cellClick(data: any, column) {

    if (this.commonJs.checkAccess('projects', 'edit', { 'baiPass': ['admin'] })) {
      if (column == 'exit_date' || column == 'attrition_type' || column == 'exit_reason' || column == 'tenure') {
        this.openSideWindow('edit', data.id);
      } else {
        this.openSideWindow('edit', data.id);
      }
    }
  }

  // Manage col sidebar open
  openManageColWindow() {
    this.projectsColumnData['column'] = columns('projects');
    this.projectsColumnData['key'] = 'projects_list_column';
    this.isMangeColumnsOpened = true;
  }

  // To ask delete confirmation
  confirmDelete(id, name) {
    const config = {
      initialState: {
        title: 'Delete',
        message: "Are you sure you want to delete " + name + "?",
      },
      class: 'modal-md custom-modal-css',
      centered: true,
      animated: false
    };
    this.bsModalRef = this.modalService.show(ConfirmationPopupComponent, config);
    // Subscribe to the onConfirmAction event
    this.bsModalRef.content.onConfirmAction.subscribe(() => {
      // Handle the confirmation logic here
      this.dataService.deleteData('projects' + 'Destroy', { id })
        .subscribe((success) => {
          if (success.status == 'error') {
            this.toastrService.notify('error', '', success.message);
          } else {
            this.toastrService.notify('success', '', success.message);
            this.isLoading = true;
            this.getProjectList();
          }
        }, (rejected) => {

        });
    });
  }

  // To get projects list
  getProjectList() {
    this.isLoading = true;
    this.gridService.postData('projectsList', this.config).subscribe((res: any) => {
      if (this.projectObj.isProjectPermissions['view'] && localStorage.getItem('role') == 'admin') {
        this.totalItems = res.totalElements;
        this.totalCount = res.totalElements;
        this.temp = [...res.data];
        this.rows = this.temp;
        this.dataService.getProjectListByUser = this.rows;
        this.dataService.getAllProjectList();
      }
      else {
        this.rows = this.dataService.getProjectListByUser;
        // this.rows = [...res.data];
        this.totalCount = this.rows.length;
        this.totalItems = this.rows.length;
      }

      this.config.export = false;
      this.isLoading = false;
    });
  }

}
