import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonJs } from '../common/common-js';
import { Router } from '@angular/router';
import { ToastrService } from '../services/toastr.service';
import * as _ from "lodash";
import { GridService } from '../services/grid.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../services/auth/auth.service';



@Injectable()
export class CustomInterceptor implements HttpInterceptor {
    private activeRequests = 0;
    bsModalRef: BsModalRef;

    constructor(
        private spinner: NgxSpinnerService,
        private modalService: BsModalService,
        public commonJs: CommonJs,
        private router: Router,
        private toastrService: ToastrService,
        public authService: AuthService,
    ) {
    }
    /**
     * Intercept
     *
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Determine if the request requires 'Access-Code' header or not
        const requiresAccessCodeHeader = !this.isLoginRequest(request);

        // Detarmine if the request requires from project level
        const requestFromProjectLevel = this.checkIsProjectAPicall();

        const authRequest = request.clone({
            setHeaders: {
                ...(!request.url.includes('login') && { Authorization: 'Bearer ' + localStorage.getItem('token') }),
                // Authorization: localStorage.getItem('token'),
                TZ: Intl.DateTimeFormat().resolvedOptions().timeZone,
                ...((!request.url.includes('commentStore') && !request.url.includes('addScripts')) && { 'Content-Type': 'application/json' }),
                ...(requiresAccessCodeHeader && { 'Access-Code': localStorage.getItem('access_code') ? localStorage.getItem('access_code') : '' }),
                ...(requestFromProjectLevel && { 'pAccess-Code': requestFromProjectLevel }),
            }
        });
        // Modify the request or perform any other actions here
        // For example, you can add headers or authentication tokens
        // Clone the request and modify it
        // Pass the modified request to the next interceptor or the HTTP handler
        let authCheck = localStorage.getItem('role');
        return next.handle(authRequest).pipe(
            finalize(() => {

            }),
            tap((error: any) => {
                this.modalService.hide(777);
            }),
            catchError((error: any) => {
                // console.log("error", error);
                this.modalService.hide(777);


                if (error.status === 401) {

                  if(authCheck != 'superadmin'){
                    this.logoutAccount();
                  }

                } else if (error.status === 500) {
                }
                // Rethrow the error to propagate it to the caller
                return throwError(error);
            })
        );
    }

    // Check if the request is a login request
    private isLoginRequest(request: HttpRequest<any>): boolean {
        return request.url.includes('login'); // Update this condition if your login URL is different
    }

    // Check if the request is project level
    private checkIsProjectAPicall() {
        const projectHeader = _.includes(this.router.url, 'projects');
        if (projectHeader) {
            var routerURL = this.router.url.split("/");
            const index = routerURL.findIndex((col) => {
                return col == 'projects';
            });
            return routerURL[index + 2]
        } else {
            return null;
        }
    }

    // Logout account
    logoutAccount() {
        this.spinner.show();
        localStorage.setItem('userId', '');
        localStorage.setItem('user', '');
        localStorage.removeItem('currentTask');
        localStorage.removeItem('projectRoles');
        localStorage.removeItem('projectRoleType');
        localStorage.removeItem('projectPermissions');
        localStorage.setItem('roles', JSON.stringify({}));
        localStorage.setItem('role', '');
        localStorage.setItem('standalone', undefined);
        localStorage.setItem('token', '');
        localStorage.setItem('access_code', '');
        let client_code = localStorage.getItem('client_code');
        this.router.navigate(['/ac/' + client_code]);
        this.spinner.hide();
    }
}
