import { Injectable } from '@angular/core';
import { Router, CanActivate, Params, NavigationEnd, NavigationStart, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataService } from '../data.service';
import * as _ from "lodash";
import { ToastrService } from '../../services/toastr.service';
import { AuthService } from '../../services/auth/auth.service';
import { CommonJs } from '../../common/common-js';
import { AppComponent } from '../../app.component';
import { map } from 'rxjs/operators';
@Injectable()
export class AuthGuardService implements CanActivate {

  navigationEnded: any;
  permissions = [];
  access = [];
  roles = [];
  allowRoles = [];
  baiPass = [];
  checkMore: boolean = true;
  projectPer = {};
  projectrole = {};
  projectrolestype = {};

  constructor(
    private router: Router,
    private dataService: DataService,
    private toastrService: ToastrService,
    public commonJs: CommonJs, private app: AppComponent
  ) { }

  canActivate(data: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // console.log(' CAN ACTIVATE ');

    let clientCode = localStorage.getItem('client_code');
    if (data.params.access_code != undefined) {
      if (localStorage.getItem('token')) {
        let config = "1";
        //let config = JSON.parse(localStorage.getItem('config'));
        //Sass
        if (config == "1") {
          //check if user tries to access other accounts route
          if (data.params.access_code != localStorage.getItem('client_code')) {
            if (localStorage.getItem('client_code') == 'SA') {
              this.router.navigate(['/superadmin/unauthorized']);
            } else {
              this.router.navigate(['/ac/' + data.params.access_code + '/unauthorized']);
            }
            return false;
          }
        } else {
          if (data.params.access_code != 'admin') {
            this.router.navigate(['unauthorized']);
            this.toastrService.notify('warn', '', 'You do not have access permission for this module, please contact admin');
            return false;
          }
        }
      } else {
        this.router.navigate(['/ac/' + data.params.access_code]);
        return false;
      }
    }

    if (localStorage.getItem('token')) {
      if (clientCode != 'sa' && clientCode != 'SA') {
        // this.checkAccountActivation();
      }

      if (data.data.checkProject && data.data.baiPass != localStorage.getItem('role')) {
        if(data.params.id){
          this.commonJs.getProjectRole(data.params.id);
        }

        //get project permissions
        return this.dataService.getOtherData('getPermissions', { 'project_id': data.params.id })
          .pipe(map((success) => {
            this.permissions = success;
            if (localStorage.getItem('projectPermissions') != null) {
              this.projectPer = JSON.parse(localStorage.getItem('projectPermissions'));
            } else {
              this.projectPer = {};
            }
            this.projectPer['project_' + data.params.id] = success;
            localStorage.setItem('projectPermissions', JSON.stringify(this.projectPer));
            localStorage.setItem('permissions', JSON.stringify(success));
            this.checkMore = true;
            this.dataService.permissionChanged("");
            return this.checkAccessDetail(data);
          }));

      } else {
        if (localStorage.getItem('permissions') != 'undefined') {
          this.permissions = JSON.parse(localStorage.getItem('permissions'));
        }
        this.checkMore = true;

        return this.checkAccessDetail(data);
      }

      //}, 0);
    } else {

      this.router.navigate(['/superadmin']);
      return false;
    }

  }

  //! Check Account Active or not

  checkAccountActivation() {
    this.dataService.getOtherData('account-activation', {})
      .subscribe((success) => {
        if (success.status != 'success') {
          let client_code = localStorage.getItem('client_code');
          localStorage.removeItem('token');
          this.app.logout(true);
          // this.router.navigate(['/ac/'+client_code]);
          this.toastrService.notify('warn', '', success.message);
        }
      }, (error) => {
        localStorage.removeItem('token');
        let err = error.json();
        //this.toastrService.notify('warn','',error.error);
        if (err.error == "Unauthenticated") {
          this.app.logout(true, 'Unauthenticated', err.error);
        } else {
          this.app.logout(true, 'account-deleted', err.error);
        }
      });
  }

  // ! Check Access Permisson Detail

  checkAccessDetail(data) {
    this.allowRoles = data.data.allowRoles as Array<string>;
    this.baiPass = data.data.baiPass as Array<string>;
    this.roles = data.data.roles as Array<string>;
    this.access = data.data.permissions as Array<string>; //like permissions: { assets: ['view'] } project-routing

    if (this.allowRoles != undefined && Object.keys(this.allowRoles).length > 0) {
      let that = this;
      let checkAcc1 = false;
      _.forEach(this.allowRoles, function (value) {
        if (value == localStorage.getItem('role')) {
          checkAcc1 = true;
        }
      });

      if (!checkAcc1) {
        that.router.navigate(['unauthorized']);
        that.toastrService.notify('warn', '', 'You do not have access permission for this module, please contact admin.');
        return false;
      } else {
        this.checkMore = true;
      }

    } else if (this.baiPass != undefined && Object.keys(this.baiPass).length > 0) {
      let that = this;
      let checkAcc3 = false;
      _.forEach(this.baiPass, function (value) {
        if (value == localStorage.getItem('role')) {
          checkAcc3 = true;
        }
      });
      if (!checkAcc3) {
        this.checkMore = true;
      } else {
        this.checkMore = false;
      }
    } else if (this.roles != undefined && Object.keys(this.roles).length > 0) {
      let that = this;
      let checkAcc2 = false;
      _.forEach(this.roles, function (value) {
        if (value == localStorage.getItem('role')) {
          checkAcc2 = true;
        }
      });
      if (!checkAcc2) {
        this.router.navigate(['unauthorized']);
        this.toastrService.notify('warn', '', 'You do not have access permission for this module, please contact admin.');
        return false;
      } else {

        this.checkMore = false;
        return true;
      }
    }

    if (this.checkMore && this.permissions && this.access != undefined && Object.keys(this.access).length > 0) {
      let that = this;
      let checkAcc = false;
      _.forEach(this.permissions, function (value) {
        _.forEach(that.access, function (val, key) {
          if (value.slug == key) {
            _.forEach(val, function (acce) {
              if (value[acce + '_data']) {
                checkAcc = true;
              }
            });
          }
        });
      });
      if (checkAcc) {
        return true;
      } else {
        this.router.navigate(['unauthorized']);
        this.toastrService.notify('warn', '', 'You do not have access permission for this module, please contact admin.');
        return false;
      }
    }
    return true;
  }
}
