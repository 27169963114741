import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { ToastrService } from '../../services/toastr.service';
import swal from 'sweetalert2';
import { ActivatedRoute, Params } from '@angular/router';
import { AppConfigs } from '../../common/app-configs';
declare var $: any;
import * as _ from "lodash";
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { GridService } from 'src/app/services/grid.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProgressbarModalComponent } from '../progressbar-modal/progressbar-modal.component';
@Component({
	selector: 'app-import',
	templateUrl: './import.component.html',
	styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {
	@Input() type: string;
	@Input() from: string;
	@Output() closeImport = new EventEmitter();

	entityName = 'Import';
	entityNameSingular = 'Import';
	currentEntity: string;
	apiRoutePrefix = 'import';
	projectId = '';
	importForm: any;
	file_name: string;
	file_type: string;
	url: any;
	preview_src: string = '';
	SaveButtonText: string = 'SAVE';
	SaveButtonImg: string = "";
	errors: { [index: string]: any } = {};
	is_image_upload: boolean = false;
	isHelp: boolean = false;
	isEmpHelp: boolean = false;
	isShotHelp: boolean = false;
	isAssetHelp: boolean = false;
	isTaskAssignmentHelp: boolean = false;
	isTasksStatusUpdateHelp: boolean = false;
	isManualTasksHelp: boolean = false;
	isDepartmentPlanningHelp: boolean = false;
	isSequenceHelp: boolean = false;
	isTaskTransferHelp: boolean = false;
	isVendorsHelp: boolean = false;

	EmpColumns = [
		{ "column": "Status", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Active / Inactive" },
		{ "column": "Vani Access", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Yes / No" },
		{ "column": "Email", "type": "email", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if VANI Access is set to 'Yes' Must be Unique" },
		{ "column": "Password", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if VANI Access is set to 'Yes', Password must be at least 8 char long." },
		{ "column": "Name", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "" },
		// { "column": "Image", "type": "Text", "required": "", "desc": "Image name" },
		{ "column": "Phone", "type": "Text", "required": "", "desc": "" },
		{ "column": "DOB", "type": "mm/dd/yyyy", "required": "", "desc": "" },
		{ "column": "Gender", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Male / Female / Other" },
		{ "column": "Address1", "type": "Text", "required": "", "desc": "" },
		{ "column": "Address2", "type": "Text", "required": "", "desc": "" },
		{ "column": "City", "type": "Text", "required": "", "desc": "" },
		{ "column": "State", "type": "Text", "required": "", "desc": "" },
		{ "column": "Country", "type": "Text", "required": "", "desc": "" },
		{ "column": "Zip", "type": "Text", "required": "", "desc": "" },
		{ "column": "Mode", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be  Contract / Payroll / Intern / Trainee" },
		{ "column": "ECode", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Unique" },
		{ "column": "Role", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be from roles in system E.g. Artist" },
		{ "column": "Gov Id", "type": "Text", "required": "", "desc": "Must be Unique" },
		{ "column": "DOJ", "type": "mm/dd/yyyy", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "" },
		{ "column": "Location", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be a value from emp_location list. e.g. London" },
		{ "column": "Vert", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be 2D / 3D / Shared" },
		{ "column": "Designation", "type": "Text", "required": "", "desc": "" },
		{ "column": "Dept", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Primary Department of employee. Must be from Settings > Phases > department list E.g. Set Modeling" },
		{ "column": "Secondary Dept", "type": "Text", "required": "", "desc": "Comma separated multiple values list from department list" },
		{ "column": "Reporting To", "type": "Text", "required": "", "desc": "Ecode of Reporting to person" },
		{ "column": "Prod Person", "type": "Text", "required": "", "desc": "Ecode of Production person. (RoleType of the employee must be 'Production'" },
		{ "column": "Soft Skills", "type": "Text", "required": "", "desc": "Comma separated multiple values from emp_software_skills list. E.g. AC3D, Tupi, Voodo" },
		{ "column": "Exp_Duration", "type": "Text", "required": "", "desc": "" },
		{ "column": "Exp_Designation", "type": "Text", "required": "", "desc": "" },
		{ "column": "Exp_Company", "type": "Text", "required": "", "desc": "" },
		{ "column": "Exit Date", "type": "mm/dd/yyyy", "required": "", "desc": "" },
		{ "column": "Attr Type", "type": "Text", "required": "", "desc": "Must be Voluntary / Involuntary" },
		{ "column": "Reason for Exit", "type": "Text", "required": "", "desc": "Must be a value from emp_reasons_for_exit list" },
		{ "column": "Col 1", "type": "Text", "required": "", "desc": "" },
		{ "column": "Col 2", "type": "Text", "required": "", "desc": "" },
		{ "column": "Col 3", "type": "Text", "required": "", "desc": "" },
		{ "column": "Col 4", "type": "Text", "required": "", "desc": "" },
		{ "column": "Col 5", "type": "Text", "required": "", "desc": "" },
		{ "column": "Col 6", "type": "Text", "required": "", "desc": "" },
	];
	ShotColumns = [
		{ "column": "Season #", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if project is Episodic Series Current project must have a season with this Season #" },
		{ "column": "Episode #", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only when project is Episodic Series Current project must have a episode with this Episode # for given season" },
		{ "column": "Sequence #", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Current project must have a sequence with this Sequence # for given season and episode combination." },
		{ "column": "Shot Number", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Unique per Sequence" },
		// { "column": "Image", "type": "Text", "required": "", "desc": "Image name" },
		{ "column": "Complexity", "type": "Text", "required": "", "desc": "Must be Simple / Medium / Complex / Very Complex" },
		{ "column": "No of Frames", "type": "Numeric", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Numeric inputs only" },
		{ "column": "Frames In & Out", "type": "Numeric", "required": "", "desc": "Frmae In & Out shuold required fill this formate[10,20,30]" },
		{ "column": "Cameras", "type": "Text", "required": "", "desc": "Cameras shuold required fill this formate[cam1,cam2,cam3]" },
		{ "column": "Description", "type": "Text", "required": "", "desc": "" },
		{ "column": "Departments", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Task department name. Must be from Settings > Phases > department list E.g. Story, for Department task types enter task type name only." },
		{ "column": "Final Department", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Task department name. Must be from Settings > Phases > department list E.g. Story, for Department task types enter task type name only." },
		{ "column": "Assets", "type": "Text", "required": "", "desc": "Asset Codes must match Asset Code from that project Multiple values comma separated" },
		{ "column": "Status", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Active / OOP" },
		{ "column": "Reel", "type": "Text", "required": "", "desc": "" },
		{ "column": "Edit in", "type": "Text", "required": "", "desc": "" },
		{ "column": "Edit Out", "type": "Text", "required": "", "desc": "" },
		{ "column": "Scan In", "type": "Text", "required": "", "desc": "" },
		{ "column": "Scan Out", "type": "Text", "required": "", "desc": "" },
	];
	AssetColumns = [
		{ "column": "Name", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be unique for this project" },
		// { "column": "Image", "type": "Text", "required": "", "desc": "Image name" },
		{ "column": "Code", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Unique for this project" },
		{ "column": "Type", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Character / Set / Prop" },
		{ "column": "Sub Type", "type": "Text", "required": "", "desc": "Must be a value from assets_subtypes list." },
		{ "column": "Complexity", "type": "Text", "required": "", "desc": "Must be Simple / Medium / Complex / Very Complex" },
		{ "column": "Description", "type": "Text", "required": "", "desc": "" },
		{ "column": "Status", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Active / OOP" },
	];

	TaskAssignmentColumns = [
		{ "column": "Id", "type": "Number", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Task #", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "This is system generated required field. This field is required only for manual tasks. Please do not edit this field." },
		{ "column": "Task Name", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "This is system generated required field. This field is required only for manual tasks. Please do not edit this field." },
		{ "column": "Info", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Department", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Version", "type": "Text", "required": "", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Status", "type": "Text", "required": "", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Type", "type": "Text", "required": "", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Undertaking", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "In House' if task is assigned to Artist. If task is assigned to vendor than value should be 'Outsourced'." },
		{ "column": "Priority", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Can be High / Normal / Low." },
		{ "column": "Assigned To", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Employee code / Vendor Code." },
		{ "column": "Desc", "type": "Text", "required": "", "desc": "" },
		{ "column": "Bid Days", "type": "Number", "required": "", "desc": "Required only if task is assigned to Artist." },
		{ "column": "Start Time", "type": "mm/dd/yyyy hh:mm", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Time must be in 24 hr format. E.g. 21:00 for 9:00 PM." },
		{ "column": "End Time", "type": "mm/dd/yyyy hh:mm", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Time must be in 24 hr format. E.g. 21:00 for 9:00 PM." },
	];

	TasksUpdateColumns = [
		{ "column": "Id", "Task Id": "Number", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Task Version Id", "type": "Number", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Task Name", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Department", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Version", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Status", "type": "Text", "required": "", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Received Date", "type": "Text", "required": "", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "To Status", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Status must match exactly with what is avilable in the system. Status must also be according to logical task review flow of this system." },
		{ "column": "Comment", "type": "Text", "required": "", "desc": "" },
	];

	ManualTasksColumns = [
		{ "column": "Department", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Task department name. Must be from Settings > Phases > department list E.g. Story, for Department task types enter task type name only." },
		{ "column": "Season #", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if project is an episodic series. Must match with season # of this project in system." },
		{ "column": "Episode #", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if project is an episodic series. Must match with season # and Episode # combination of this project in system." },
		{ "column": "Sequence #", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if project is not an episodic series. Must match with Sequence # of this project in system." },
		{ "column": "Name", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "" },
		{ "column": "Number", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Unique per task" },
		{ "column": "Type", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be R&D / Production" },
		{ "column": "Thumbnail", "type": "Text", "required": "", "desc": "Thumbnail name" },
		{ "column": "Description", "type": "Text", "required": "", "desc": "" }
	];

	departmentPlanningColumns = [
		{ "column": "Project #", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Season #", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field. Field avilable when project is Episodic Series." },
		{ "column": "Episode #", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field. Field avilable when project is Episodic Series." },
		{ "column": "Episode Id", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field. Field avilable when project is Episodic Series." },
		{ "column": "Sequence #", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field. Field avilable when project is Non-Episodic." },
		{ "column": "Sequence Id", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Department", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Planned Start Date", "type": "mm/dd/yyyy", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be in following format. E.g. 01/15/2020, Format this field as Text before entering data in excel." },
		{ "column": "Planned End Date", "type": "mm/dd/yyyy", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be in following format. E.g. 01/15/2020, Format this field as Text before entering data in excel." },
		{ "column": "Planned Bid Days", "type": "Text", "required": "", "desc": "Planned bids for the department." },
	];

	SequenceColumns = [
		{ "column": "Season #", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if project is Episodic Series Current project must have a season with this Season #" },
		{ "column": "Episode #", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only when project is Episodic Series Current project must have a episode with this Episode # for given season" },
		{ "column": "Sequence Number", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Unique per Sequence" },
		{ "column": "Sequence Name", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Sequence Name" },
		// { "column": "Image", "type": "Text", "required": "", "desc": "Image name" },
		{ "column": "Description", "type": "Text", "required": "", "desc": "" },
		{ "column": "Assets", "type": "Text", "required": "", "desc": "Asset Codes must match Asset Code from that project Multiple values comma separated" },
		{ "column": "Lead", "type": "Text", "required": "", "desc": "Lead Ecode, Lead must be added in project team. " },
		{ "column": "Producer", "type": "Text", "required": "", "desc": "Producer Ecode, Producer must be added in project team." },
		{ "column": "Projected Shots", "type": "Text", "required": "", "desc": "Projected shots for the sequence." },
		{ "column": "Projected Seconds", "type": "Text", "required": "", "desc": "Projected seconds for the sequence." },
		{ "column": "Complexity", "type": "Text", "required": "", "desc": "Must be Simple / Medium / Complex / Very Complex" },
		{ "column": "Status", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Active / Inactive" },
	];

	TaskTransferColumns = [
		{ "column": "Project Id", "type": "Number", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Id", "type": "Number", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "TA Id", "type": "Number", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Info", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Department", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Version", "type": "Text", "required": "", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Status", "type": "Text", "required": "", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Type", "type": "Text", "required": "", "desc": "This is system generated required field. Please do not edit this field." },
		{ "column": "Undertaking", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "In House' if task is assigned to Artist. If task is assigned to vendor than value should be 'Outsourced'." },
		{ "column": "Priority", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Can be High / Normal / Low." },
		{ "column": "Assigned To", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Employee code / Vendor Code." },
		{ "column": "Transfer To", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Employee code / Vendor Code." },
		{ "column": "Start Time", "type": "mm/dd/yyyy hh:mm", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Time must be in 24 hr format. E.g. 21:00 for 9:00 PM." },
		{ "column": "End Time", "type": "mm/dd/yyyy hh:mm", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Time must be in 24 hr format. E.g. 21:00 for 9:00 PM." },
		{ "column": "Bid Days", "type": "Number", "required": "", "desc": "Required only if task is assigned to Artist." },
		{ "column": "Desc", "type": "Text", "required": "", "desc": "Desc of task " },
	];

	VendorsColumns = [
		{ "column": "Status", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Active / Inactive" },
		{ "column": "Vani Access", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be Yes / No" },
		{ "column": "Email", "type": "email", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if VANI Access is set to 'Yes' Must be Unique" },
		{ "column": "Password", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if VANI Access is set to 'Yes', Password must be at least 8 char long." },
		{ "column": "Name", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "" },
		// { "column": "Ima	ge", "type": "Text", "required": "", "desc": "Image name" },
		{ "column": "Phone", "type": "Text", "required": "", "desc": "" },
		{ "column": "Address1", "type": "Text", "required": "", "desc": "" },
		{ "column": "City", "type": "Text", "required": "", "desc": "" },
		{ "column": "State", "type": "Text", "required": "", "desc": "" },
		{ "column": "Country", "type": "Text", "required": "", "desc": "" },
		{ "column": "Zip", "type": "Text", "required": "", "desc": "" },
		{ "column": "Vendor Code", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Vendor Code" },
		{ "column": "Department", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Comma separated multiple values list from department list" },
		{ "column": "Grade", "type": "Text", "required": "", "desc": "Vendor Grade" },
		{ "column": "Type", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be  Vendor / Freelance " },
		{ "column": "Team Count", "type": "Text", "required": "", "desc": "Vendor Team Count" },

	];

	uploadUrl = this.appConfigs.getUploadUrl();
	projectroles = JSON.parse(localStorage.getItem('projectRoles'));
	clientCode = localStorage.getItem('access_code');
	isSubmitted: boolean = false;
	public files: NgxFileDropEntry[] = [];
	haveDepts: boolean = true;
	bsModalRef: BsModalRef;


	empCustomColumns = [];
	constructor(
		private dataService: DataService,
		private toastrService: ToastrService,
		private activatedRoute: ActivatedRoute,
		public appConfigs: AppConfigs,
		private gridService: GridService,
		private modalService: BsModalService,
	) { }


	ngOnInit() {
		this.activatedRoute.params.subscribe((params: Params) => {
			this.projectId = params['id'];
		});
		if (this.type == 'Tasks Assignment') {
			this.currentEntity = 'TaskAssignments';
		} else if (this.type == 'Tasks Status Update') {
			this.currentEntity = 'TasksStatusUpdate'
		} else if (this.type == 'Pre / Post / R&D Tasks') {
			this.currentEntity = 'manual-tasks'
		} else if (this.type == 'Episodes Department Planning' || this.type == 'Sequences Department Planning') {
			this.currentEntity = 'department-planning';
			this.isDepartmentPlanningHelp = true;
		} else if (this.type == 'Link Departments') {
			this.currentEntity = 'link-departments'
		} else if (this.type == 'sequences') {
			this.currentEntity = 'sequences'
		} else if (this.type == 'Bulk Task Transfer') {
			this.currentEntity = 'bulk-task-transfer'
		} else if (this.type == 'Bulk Shot Update') {
			this.currentEntity = 'importShotsUpdate'
		} else if (this.type == 'Bulk Asset Update') {
			this.currentEntity = 'importAssetUpdate'
		} else if (this.type == 'vendors') {
			this.currentEntity = 'vendors';
		} else {
			this.currentEntity = this.type;
			this.haveDepartments(this.type, this.projectId);
		}
		this.errors.file = '';
		this.errors.haveDepts = '';

		if (this.currentEntity == 'employees') {
			this.getEmployeeCustomColumns();
		}


		/** Import help from modal window */

		// console.log("this.type", this.from);

		if (this.type == 'employees' && this.from == 'modalImport') {
			this.isHelp = true;
			this.isEmpHelp = true;
		} else if (this.type == 'shot' && this.from == 'modalImport') {
			this.isHelp = true;
			this.isShotHelp = true;
		} else if (this.type == 'sequence' && this.from == 'modalImport') {
			this.isHelp = true;
			this.isSequenceHelp = true;
		} else if (this.type == 'asset' && this.from == 'modalImport') {
			this.isHelp = true;
			this.isAssetHelp = true;
		}
		else if (this.type == 'vendors' && this.from == 'modalImport') {
			this.isHelp = true;
			this.isVendorsHelp = true;
		}
		else if ((this.type == 'task') && this.from == 'modalImport') {
			this.isHelp = true;
			this.isManualTasksHelp = true;
		}
	}

	onSubmit(form: NgForm) {
		this.isSubmitted = true;
		this.activatedRoute.params.subscribe((params: Params) => {
			this.projectId = params['id'];
		});
		if (this.url != undefined) {
			form.value.file = this.url;
			form.value.file_name = this.file_name;
		}
		if (this.projectId != null) {
			form.value.project_id = this.projectId;
		} else {
			form.value.project_id = '';
		}
		if (this.is_image_upload) {
			form.value.image_upload = this.is_image_upload;
		} else {
			form.value.image_upload = false;
		}
		if (this.currentEntity == 'TasksStatusUpdate') {
			form.value.currentRole = this.projectroles['project_' + this.projectId];
		}
		if (form.value.file != undefined && form.value.file != '') {
			this.errors.file = '';
			this.SaveButtonText = 'SAVING';
			this.gridService.apiProgress = 0;
			this.bsModalRef = this.modalService.show(ProgressbarModalComponent, {
				backdrop: 'static',
				keyboard: false,
				class: 'modal-dialog-centered'
			});
			setTimeout(() => {
				this.close();
			}, 2);
			this.dataService.storeData(this.apiRoutePrefix + '/' + this.currentEntity, form.value)
				.subscribe((response) => {
					console.log(response);
					if (response.status != 503) {
						if ((response.url != '') && !response.fileTypeError) {
							// console.log(response.message);
							// console.log(response.message.includes(' 0 records were returned with errors'), this.apiRoutePrefix);
							let successMsg = response.message ? response.message : false;
              console.log(successMsg);
							if (successMsg && successMsg.includes(' 0 records were returned with errors')) {  // (this.apiRoutePrefix=='importAssetUpdate' || this.apiRoutePrefix=='importShotsUpdate')

								this.toastrService.notify('success', '', 'Data updated successfully.');

								//  swal.fire({
								//    title: 'Imported Successfully',
								//    text: response.message,
								//    icon: "success",
								//    showCancelButton: false,
								//    confirmButtonColor: "#DD6B55",
								//    confirmButtonText: "Okay",
								//  }).then((result) => {
								//      if (result.value) {
								//      }
								//    });

							} else {

								swal.fire({
									title: 'Import Error',
									text: response.message,
									icon: "warning",
									showCancelButton: false,
									confirmButtonColor: "#DD6B55",
									confirmButtonText: "Okay",
								}).then((result) => {
									if (result.value) {
									}
								});

							}
							if (response.url != '') {
								window.open(response.url, '_self');
							}
						}
						else if (response.image_error == true) {
							this.errors.isZip = true;
							this.SaveButtonText = 'SAVE';
							this.isSubmitted = false;
							return false;
						} else if (response.is_image_uploded == true) {
							this.toastrService.notify('success', '', 'Images uploded successfully.');
						} else if (response.fileTypeError) {
							this.errors.fileTypeError = true;
							this.SaveButtonText = 'SAVE';
							this.isSubmitted = false;
							return false;
						} else {
							this.toastrService.notify('success', '', 'Data imported successfully.');
						}
						if (this.currentEntity == 'TaskAssignments') {
							if (response.notifications && response.notifications.length > 0) {
								let that = this;
								_.forEach(response.notifications, function (notification, key) {
									that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
								});
							}
						}
						this.isSubmitted = false;
						if (response.is_image_uploded == true) {
						} else {

						}
						this.is_image_upload = false;
						this.SaveButtonText = 'SAVED';
						this.SaveButtonText = 'SAVE';
						this.gridService.apiProgress = 100;
						this.bsModalRef.hide();
					} else {
						this.is_image_upload = false;
						this.SaveButtonText = 'SAVED';
						this.SaveButtonText = 'SAVE';
						this.gridService.apiProgress = 100;
						this.bsModalRef.hide();
						this.toastrService.notify('warn', '', 'Under maintanace please try after some time ')
					}
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
						if ((this.currentEntity == 'shots' || this.currentEntity == 'assets') && err.message == 'No Departments') {
							this.haveDepts = true;
						} else {
							this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
						}
						this.gridService.apiProgress = 100;
						this.bsModalRef.hide();
						this.SaveButtonText = "SAVE";
						this.isSubmitted = false;
					}
				});
			this.gridService.progressFunction();
		}
		else {
			this.errors.file = 'Please Select File';
			this.isSubmitted = false;
		}

	}

	onChange(flag) {
		if (flag) {
			this.is_image_upload = true;
		} else {
			this.is_image_upload = false;
		}
	}

	downloadFile() {
		//window.location.href = 'http://localhost/'+window.location.pathname+'/vani-offline/public/import/Template/Import_Assets.xls';
		//return true;
		this.dataService.storeData(this.apiRoutePrefix + 'download', { type: this.type })
			.subscribe((response) => {
				if (response.data != '') {
					let appLocation = window.location.origin;
					let fileName = '';
					if (response.entity == 'assets') {
						fileName = 'Import_Assets.xls';
					} else if (response.entity == 'shots') {
						fileName = 'Import_Shots.xls';
					} else if (response.entity == 'Manual Tasks') {
						fileName = 'Import_Manual_Tasks.xls';
					} else if (response.entity == 'employees') {
						fileName = 'Import_Employees.xls';
					} else if (response.entity == 'sequences') {
						fileName = 'Import_Sequences.xls';
					} else if (response.entity == 'vendors') {
						fileName = 'Import_Vendors.xls';
					}
					if (appLocation == "https://app.vanisoftware.com" || appLocation == "http://app.vanisoftware.com" || appLocation == "http://app.vanisoftware.com" || appLocation == "https://app.vanisoftware.com") {
						//https://vanisoftware.com/api/public/import/Template//Import_Assets.xls
						//window.location.href = appLocation +'/api/public/import/Template/'+response.data;
						//window.location.href = appLocation +'/api/'+response.data;
						window.location.href = response.data;
					} else if (appLocation == '"http://wts"') {
						//window.location.href = appLocation+'/wtshub/vani-offline/'+response.data;
						window.location.href = response.data;
						//window.location.href = appLocation +'/'+ response.data;
					} else if (appLocation == 'http://127.0.0.1:4200') {
						//window.location.href = response.data;
						//window.open('http://10.13.1.31/vani-offline/public/import/Template/'+fileName,'Download');
						//window.open('http://localhost/vani-offline/public/import/Template/'+fileName,'Download');
						let host = window.location.host;
						window.open('http://' + host + '/vani-offline/public/import/Template/' + fileName, 'Download');
					} else {
						//window.location.href = response.data;
						let host = window.location.host;
						window.open('http://' + host + '/vani-offline/public/import/Template/' + fileName, 'Download');
					}
				}
			}, (error) => {
				this.toastrService.notify('warn', '', 'Sorry..Try Again.');
			});
	}

	filesUpdated(files) {
		this.errors.file = '';
		$('.file_dropZone_internal').removeClass('over');
		let key = files.length - 1;
		if (files && files[key]) {
			var reader = new FileReader();
			this.file_name = files[key].name;
			this.file_type = files[key].type;
			reader.onload = (event: any) => {
				this.url = event.target.result;
				if (this.file_type != 'image/png' && this.file_type != 'image/jpg' && this.file_type != 'image/jpeg' && this.file_type != 'image/gif') {
					//this.preview_src = './assets/images/default/attechment.png';
					this.preview_src = './assets/images/default/attechment.png';
				}
				else {
					this.preview_src = this.url;
				}

			}
			reader.readAsDataURL(files[key]);
		}
	}

	public dropped(files: NgxFileDropEntry[]) {

		this.files = files;
		for (const droppedFile of files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {

				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {

					// Here you can access the real file
					//console.log(droppedFile.relativePath, file);

					var reader = new FileReader();
					this.file_name = file.name;
					this.file_type = file.type;

					reader.onload = (event: any) => {
						this.url = event.target.result;

						// console.log(files,this.file_type)
						// if(this.file_type!='application/vnd.ms-excel' && this.file_type!='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
						//   return;
						// }

						if (this.file_type != 'image/png' && this.file_type != 'image/jpg' && this.file_type != 'image/jpeg' && this.file_type != 'image/gif') {
							this.preview_src = './assets/images/default/attechment.png';
						} else {
							this.preview_src = this.url;
						}
					}
					reader.readAsDataURL(file);
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
				//console.log(droppedFile.relativePath, fileEntry);
			}
		}
	}

	public fileOver(event) {
		//console.log(event);
	}

	public fileLeave(event) {
		//console.log(event);
	}

	unlinkImage(event) {
		//this.errors.file='Please Select File';
		event.stopPropagation();
		this.preview_src = '';
		this.file_name = '';
		this.url = '';
	}

	close() {
		this.dataService.notifyOther({ refresh: false });
		this.closeImport.emit();
	}

	toggleHelp() {
		if (this.type == 'employees') {
			if (this.isHelp) {
				this.isEmpHelp = false;
				this.isHelp = false
			} else {
				this.isEmpHelp = true;
				this.isHelp = true;
			}
		} else if (this.type == 'assets') {
			if (this.isHelp) {
				this.isAssetHelp = false;
				this.isHelp = false
			} else {
				this.isAssetHelp = true;
				this.isHelp = true;
			}
		} else if (this.type == 'shots') {
			if (this.isHelp) {
				this.isShotHelp = false;
				this.isHelp = false
			} else {
				this.isShotHelp = true;
				this.isHelp = true;
			}
		} else if (this.type == 'sequences') {
			if (this.isHelp) {
				this.isSequenceHelp = false;
				this.isHelp = false
			} else {
				this.isSequenceHelp = true;
				this.isHelp = true;
			}
		} else if (this.type == 'Tasks Assignment') {
			if (this.isHelp) {
				this.isTaskAssignmentHelp = false;
				this.isHelp = false
			} else {
				this.isTaskAssignmentHelp = true;
				this.isHelp = true;
			}
		} else if (this.type == 'Tasks Status Update') {
			if (this.isHelp) {
				this.isTasksStatusUpdateHelp = false;
				this.isHelp = false
			} else {
				this.isTasksStatusUpdateHelp = true;
				this.isHelp = true;
			}
		} else if (this.type == 'Pre / Post / R&D Tasks') {
			if (this.isHelp) {
				this.isManualTasksHelp = false;
				this.isHelp = false;
			} else {
				this.isHelp = true;
				this.isManualTasksHelp = true;
			}
		} else if (this.type == 'Episodes Department Planning' || this.type == 'Sequences Department Planning') {
			if (this.isHelp) {
				this.isDepartmentPlanningHelp = false;
				this.isHelp = false;
			} else {
				this.isHelp = true;
				this.isDepartmentPlanningHelp = true;
			}
		} else if (this.type == 'Bulk Task Transfer') {
			if (this.isHelp) {
				this.isTaskTransferHelp = false;
				this.isHelp = false;
			} else {
				this.isHelp = true;
				this.isTaskTransferHelp = true;
			}
		} else if (this.type == 'vendors') {
			if (this.isHelp) {
				this.isVendorsHelp = false;
				this.isHelp = false
			} else {
				this.isVendorsHelp = true;
				this.isHelp = true;
			}
		}
	}

	getEmployeeCustomColumns() {
		this.dataService.getSetting({ type: 'emp_custom_columns' })
			.subscribe((success) => {
				this.empCustomColumns = success.data;
				let that = this;
				let i = 0;
				_.forEach(this.EmpColumns, function (col, key) {
					if (col.column == 'Col 1' || col.column == 'Col 2' || col.column == 'Col 3' || col.column == 'Col 4' || col.column == 'Col 5' || col.column == 'Col 6') {
						that.EmpColumns[key]['desc'] = that.empCustomColumns[i];
						i++;
					}
				});
			}, (error) => {
			});
	}

	haveDepartments(type, projectId) {
		if (type == 'assets') {
			this.dataService.getListData(this.apiRoutePrefix + '/haveDepartments', { project_id: projectId, 'type': type })
				.subscribe((success) => {
					this.haveDepts = success.data;
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.SaveButtonText = 'SAVE';
						if (err.message == 'No Departments') {
							this.errors.haveDepts = err.error;
							this.haveDepts = true;
						}
					}
				});
		} else if (type == 'shots') {
			this.dataService.getListData(this.apiRoutePrefix + '/haveDepartments', { project_id: projectId, 'type': type })
				.subscribe((success) => {
					this.haveDepts = success.data;
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.SaveButtonText = 'SAVE';
						if (err.message == 'No Departments') {
							this.errors.haveDepts = err.error;
							this.haveDepts = true;
						}
					}
				});
		}
	}
}
